import { locationId, name, title, slackAlerts } from '@agp/branches/specific/bocaraton'
import { SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, ALL_DAYS } from '@agp/constants/weekdays'
import { MEMORIAL_DAY, LABOR_DAY, ROSH_HASHANAH, YOM_KIPPUR, THANKSGIVING } from '@agp/constants/holidays-2025'
import { PROMO, MEMBER, VOUCHER } from '@agp/constants/calendar-types'
import { FACIAL, BODY_CONTOURING, LASER } from '@agp/constants/service-types'

export const LOCATION_ID = locationId
export const LOCATION_NAME = name
export const LOCATION_TITLE = title
export const COMPANY_NAME = "Dolce Medical Spa"
export const COMPANY_NAME_SHORT = "Dolce"
export const COMPANY_LOGO = "https://storage.googleapis.com/highlevel-backend.appspot.com/location/MXBnHb9E2unG15D0fqCL/workflow/f1e52408-dd6b-4b4d-a0b3-a9d091f663f5/9ee7aa57-2364-45db-ad9a-209de9121edb.png"
export const COMPANY_ADDRESS = "4720 NW 2nd Ave Unit D-105, Boca Raton, FL 33431"
export const COMPANY_MAPS_LINK = "https://maps.app.goo.gl/suA5cfUWNzsSAK8B8"
export const COMPANY_PHONE = "(561) 831-7717"
export const COMPANY_WHATSAPP = "https://wa.me/15618317717"
export const COMPANY_FBMSGER = "https://m.me/Dolcemedspabocaraton"
export const REVIEW_RATING = 5
export const REVIEW_COUNT = 43
export const TIMEZONE = "America/New_York"

// external API keys and IDs
export const POSTHOG_APP_ID = "phc_OsEduFgwxnKGNkMNturxXwG1iq5yqxr6thjMabrq0Oy"
export const SLACK_NOTIFICATION_WEBHOOK = slackAlerts
export const FB_PIXEL = "1281040216672999"

// location-specific config
export const TREATMENT_ROOMS = 2
export const HOURS_OF_OPERATION_START = 10 // 10am
export const HOURS_OF_OPERATION_END = 19 // 7pm
// export const LUNCH_HOUR = 13 // 1pm
export const LUNCH_HOUR = 23 // disable lunch hour
export const APPOINTMENT_DURATION_MINS = 60
export const APPOINTMENT_BUFFER_MINS = 0
export const OPENING_DATE = "2024-12-01"
export const FUTURE_AVAILABLE_DAYS = 7
export const OPERATION_OVERRIDES = {
	[ALL_DAYS]: { maxPromoApptsPerRoom: Infinity },
		
	[MEMORIAL_DAY]: { open: true },
	[LABOR_DAY]: { open: true },
	[ROSH_HASHANAH]: { open: true },
	[YOM_KIPPUR]: { open: true },
	[THANKSGIVING]: { open: true },
}

// calendar config
export const CALENDARS = [
	{
		type: PROMO,
		service: FACIAL,
		id: "9VwVscM0jAO3OvGEHs6o",
		// id: "XSQ0ExOXrWBj8JUsmUM4", // lasolas temporary calendar
		name: "Medical Facial Promo",
		path: "facial-promo",
		public: true,
	},
	{
		type: VOUCHER,
		service: FACIAL,
		id: "97iHHBnRiRMt1gVsNg4r",
		name: "Medical Facial (Voucher)",
		path: "facial-voucher",
	},
	{
		type: MEMBER,
		service: FACIAL,
		id: "aV6hZwoFSvRpAwFl4R2d",
		name: "Medical Facial (Members)",
		path: "facial-members",
	},
	{
		type: PROMO,
		service: BODY_CONTOURING,
		id: "mQAoUnnVbvXJIwGNpGhY",
		name: "Body Contouring Promo",
		path: "bc-promo",
		public: true,
	},
	{
		type: VOUCHER,
		service: BODY_CONTOURING,
		id: "wpNn2RL9ITKVpkNbL3U1",
		name: "Body Contouring (Voucher)",
		path: "bc-voucher",
	},
	{
		type: MEMBER,
		service: BODY_CONTOURING,
		id: "F8HTwv6y7aaBjwOcdfDp",
		name: "Body Contouring (Members)",
		path: "bc-members",
	},
]

// CALENDARS[0].id = ""