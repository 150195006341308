export const JAN_FIRST = '2025-01-01'
export const EASTER = '2025-04-20' // varies
export const MEMORIAL_DAY = '2025-05-26' // varies
export const JULY_FOURTH = '2025-07-04'
export const LABOR_DAY = '2025-09-01' // varies
export const ROSH_HASHANAH_EREV = '2025-09-22' // varies
export const ROSH_HASHANAH = '2025-09-23' // varies
export const ROSH_HASHANAH_DAY_2 = '2025-09-24' // varies
export const YOM_KIPPUR_EREV = '2025-10-01' // varies
export const YOM_KIPPUR = '2025-10-02' // varies
export const SUKKOT_EREV = '2025-10-06' // varies
export const SUKKOT_DAY_1 = '2025-10-07' // varies
export const SUKKOT_DAY_2 = '2025-10-08' // varies
export const SUKKOT_DAY_6 = '2025-10-13' // varies
export const SUKKOT_DAY_7 = '2025-10-14' // varies
export const THANKSGIVING = '2025-11-27' // varies
export const XMAS = '2025-12-25'

export const COMPANY_HOLIDAYS = [ // company-wide off holidays
	JAN_FIRST,
	MEMORIAL_DAY,
	JULY_FOURTH,
	LABOR_DAY,
	ROSH_HASHANAH,
	YOM_KIPPUR,
	THANKSGIVING,
	XMAS,
]

export const ALL_HOLIDAYS = [
	JAN_FIRST,
	EASTER,
	MEMORIAL_DAY,
	JULY_FOURTH,
	LABOR_DAY,
	ROSH_HASHANAH_EREV,
	ROSH_HASHANAH,
	ROSH_HASHANAH_DAY_2,
	YOM_KIPPUR_EREV,
	YOM_KIPPUR,
	SUKKOT_DAY_1,
	SUKKOT_DAY_2,
	SUKKOT_DAY_6,
	SUKKOT_DAY_7,
	THANKSGIVING,
	XMAS,
]
